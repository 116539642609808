@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .c-slider__catchcopy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

#top .c-slider {
  padding: 0;
  line-height: 0; }
  #top .c-slider li {
    width: 100%;
    height: 720px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 568px) {
      #top .c-slider li {
        height: 500px; } }
  #top .c-slider__catchcopy {
    font-size: 48px;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    width: 70%; }
    @media screen and (max-width: 568px) {
      #top .c-slider__catchcopy {
        width: 85%;
        font-size: 30px; } }
  #top .c-slider .slick-prev, #top .c-slider .slick-next {
    z-index: 1; }
  #top .c-slider .slick-prev {
    left: 25px; }
  #top .c-slider .slick-next {
    right: 25px; }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1200px) {
    #top #wrapper {
      padding: 60px 0 0; } }
